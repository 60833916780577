import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44eefc6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-container" }
const _hoisted_2 = { class: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_faicon, { icon: "shipping-fast" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.submitting')) + "...", 1)
      ])
    ])
  ]))
}