

import { defineComponent } from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'loading',

    setup() {
        return {
            getTitleCaseTranslation,
        };
    },
});

