import { ref } from 'vue';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';
import floorTrakStore from '@/modules/floortrak/store/FloorTrakStore';
import Permissions from '@/services/permissions/Permissions';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

class SideBarNavItemManager {
    private menuItems = ref<SideBarMenuItem[]>([]);

    private profileStore = floorTrakStore.getInstance().profileStore;

    private constructor() {
        this.updateMenuItems();
    }

    private static instance: SideBarNavItemManager;

    public static getInstance(): SideBarNavItemManager {
        if (!SideBarNavItemManager.instance) {
            SideBarNavItemManager.instance = new SideBarNavItemManager();
        }

        return SideBarNavItemManager.instance;
    }

    public updateMenuItems() {
        this.menuItems.value = [
            {
                id: 1,
                title: getTranslation('core.common.general').toUpperCase(),
                type: 'header',
                isSectionHeader: true,
                show: true,
            },
            {
                id: 2,
                title: getTitleCaseTranslation('core.common.home'),
                icon: 'home',
                routeName: FloorTrakRouteTypes.HOME,
                type: 'button',
                show: true,
            },
            {
                id: 3,
                title: getTitleCaseTranslation('core.common.receiving'),
                icon: 'dolly',
                type: 'parent',
                show: this.canSeeReceivingMenu(),
                children: [
                    {
                        id: 31,
                        title: getTitleCaseTranslation('core.common.new'),
                        routeName: FloorTrakRouteTypes.RECEIVING.NEW,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.NEW],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canDockReceive(),
                    },
                    {
                        id: 32,
                        title: getTitleCaseTranslation('core.common.existing'),
                        routeName: FloorTrakRouteTypes.RECEIVING.EXISTING,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.EXISTING],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canCloseOutReceipt(),
                    },
                    {
                        id: 33,
                        title: getTitleCaseTranslation('core.common.estimate'),
                        routeName: FloorTrakRouteTypes.RECEIVING.ESTIMATE,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.ESTIMATE],
                        // @todo whats the correct permission for this now?
                        type: 'child',
                        show: Permissions.FLOORTRAK.canCloseOutReceipt(),
                    },
                    {
                        id: 34,
                        title: getTitleCaseTranslation('core.common.sort'),
                        routeName: FloorTrakRouteTypes.RECEIVING.SORT,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.SORT],
                        // @todo whats the correct permission for this now?
                        type: 'child',
                        show: Permissions.FLOORTRAK.canCloseOutReceipt(),
                    },
                    {
                        id: 35,
                        title: getTitleCaseTranslation('core.common.putAway'),
                        routeName: FloorTrakRouteTypes.RECEIVING.PUT_AWAY,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.PUT_AWAY],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canCloseOutReceipt(),
                    },
                    {
                        id: 36,
                        title: getTitleCaseTranslation('core.common.warehousePutAway'),
                        routeName: FloorTrakRouteTypes.RECEIVING.WAREHOUSE_PUT_AWAY,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.WAREHOUSE_PUT_AWAY],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canWarehousePutAway(),
                    },
                    {
                        id: 37,
                        title: getTitleCaseTranslation('core.common.closeout'),
                        routeName: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                        activeRouteNameArray: [FloorTrakRouteTypes.RECEIVING.CLOSEOUT],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canCloseOutReceipt(),
                    },
                ],
            },
            {
                id: 4,
                title: getTitleCaseTranslation('core.common.shipping'),
                icon: 'shipping-fast',
                type: 'parent',
                show: this.canSeeShippingMenu(),
                children: [
                    {
                        id: 41,
                        title: getTitleCaseTranslation('core.common.new'),
                        routeName: FloorTrakRouteTypes.SHIPPING.NEW,
                        activeRouteNameArray: [FloorTrakRouteTypes.SHIPPING.NEW],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canShipNew(),
                    },
                    {
                        id: 42,
                        title: getTitleCaseTranslation('core.common.existing'),
                        routeName: FloorTrakRouteTypes.SHIPPING.SEARCH,
                        activeRouteNameArray: [FloorTrakRouteTypes.SHIPPING.SEARCH, FloorTrakRouteTypes.SHIPPING.EXISTING],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canPickAndShipExisting(),
                    },
                    {
                        id: 43,
                        title: getTitleCaseTranslation('core.common.warehouseShipping'),
                        routeName: FloorTrakRouteTypes.SHIPPING.WAREHOUSE_SEARCH,
                        activeRouteNameArray: [FloorTrakRouteTypes.SHIPPING.WAREHOUSE_SEARCH, FloorTrakRouteTypes.SHIPPING.WAREHOUSE],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canWarehouseShip(),
                    },
                ],
            },
            {
                id: 5,
                title: getTitleCaseTranslation('core.domain.inventory'),
                type: 'parent',
                icon: 'boxes',
                show: this.canSeeInventoryMenu(),
                children: [
                    {
                        id: 51,
                        title: getTitleCaseTranslation('core.domain.cycleCount'),
                        routeName: FloorTrakRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                        activeRouteNameArray: [FloorTrakRouteTypes.CYCLE_COUNT_REPORT.REPORT],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canRecordCount(),
                    },
                    {
                        id: 52,
                        title: getTitleCaseTranslation('core.domain.warehouseMovement'),
                        routeName: FloorTrakRouteTypes.INVENTORY.TRANSFER,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.TRANSFER],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canMoveInventory(),
                    },
                    {
                        id: 53,
                        title: getTranslation('core.domain.inHouseRepairs'),
                        routeName: FloorTrakRouteTypes.INVENTORY.IN_HOUSE_REPAIRS,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.IN_HOUSE_REPAIRS],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canRepairItems(),
                    },
                    {
                        id: 54,
                        title: getTitleCaseTranslation('core.domain.addTag'),
                        routeName: FloorTrakRouteTypes.INVENTORY.ADD_TAG,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.ADD_TAG],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canMaintainTags(),
                    },
                    {
                        id: 55,
                        title: getTitleCaseTranslation('core.domain.replaceTag'),
                        routeName: FloorTrakRouteTypes.INVENTORY.REPLACE_TAG,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.REPLACE_TAG],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canMaintainTags(),
                    },
                    {
                        id: 56,
                        title: getTitleCaseTranslation('core.common.assembleUnitLoads'),
                        routeName: FloorTrakRouteTypes.INVENTORY.COMBINED_ITEMS,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.COMBINED_ITEMS],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canManageUnitLoads(),
                    },
                    {
                        id: 57,
                        title: getTitleCaseTranslation('core.common.disassembleUnitLoads'),
                        routeName: FloorTrakRouteTypes.INVENTORY.DECONSTRUCT_ITEMS,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.DECONSTRUCT_ITEMS],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canManageUnitLoads(),
                    },
                    {
                        id: 58,
                        title: getTitleCaseTranslation('core.domain.processedPutAway'),
                        routeName: FloorTrakRouteTypes.INVENTORY.PUT_AWAY,
                        activeRouteNameArray: [FloorTrakRouteTypes.INVENTORY.PUT_AWAY],
                        type: 'child',
                        show: Permissions.FLOORTRAK.canProcessPutAway(),
                    },
                ],
            },
        ];
    }

    public getSideMenuNavItems(): SideBarMenuItem[] {
        return this.menuItems.value;
    }

    private canSeeReceivingMenu(): boolean {
        return this.profileStore.isAdministrator || this.profileStore.isReceivingNew || this.profileStore.isReceivingExisting || Permissions.FLOORTRAK.canDockReceive();
    }

    private canSeeShippingMenu(): boolean {
        return this.profileStore.isAdministrator || this.profileStore.isShippingNew || this.profileStore.isShippingExisting;
    }

    private canSeeInventoryMenu(): boolean {
        return (
            this.profileStore.isAdministrator
            || this.profileStore.isInventoryAddTag
            || this.profileStore.isInventoryUpdateTag
            // this.profileStore.isInventoryStockAuditBalance || //TODO: this doesnt exist, should it?
            || this.profileStore.isInventoryStockAuditCount
            || this.profileStore.isInventoryReports
            || this.profileStore.isInventoryAssembleItem
        );
    }
}

const sideBarNavItemManager = SideBarNavItemManager;
export default sideBarNavItemManager;
