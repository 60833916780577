
import { defineComponent, onBeforeMount, watch } from 'vue';
import Loading from '@/modules/floortrak/view/shared/components/Loading.vue';
import sideBarNavItemManager from '@/modules/floortrak/router/SideBarNavItemManager';
import floorTrakStore from '@/modules/floortrak/store/FloorTrakStore';
import useLoading from '@/modules/floortrak/composables/useLoading';

export default defineComponent({
    name: 'floortrak-app',
    components: {
        Loading,
    },
    setup() {
        const sideBarManager = sideBarNavItemManager.getInstance();
        const { profileStore } = floorTrakStore.getInstance();

        const { isLoading } = useLoading();

        onBeforeMount(() => {
            watch(() => [profileStore.permissions], () => {
                sideBarManager.updateMenuItems();
            }, { immediate: true });
        });
        return {
            sideBarManager,
            isLoading,
        };
    },
});
